<template>
  <div class="relative w-full">
    <div :class="progressFlexClass">
      <VRow gap="2" class="items-center">
        <span
          v-if="props.label"
          :class="labelSizeClass"
          data-testid="progress-label"
        >
          {{ props.label }}</span
        >
        <VTooltip
          v-if="props.tooltip"
          :tooltip="props.tooltip"
          :position="props.tooltipPosition"
          data-testid="vavatar-tooltip"
        >
          <div class="w-5">
            <VIcon name="info" small />
          </div>
        </VTooltip>
      </VRow>
      <div>
        <VRow v-if="props.xl" class="items-end">
          <p
            class="text-4xl font-bold"
            data-testid="progress-percentage-xl-counter"
            :class="[
              props.counterColor,
              { 'text-gray': props.counterColor === '' },
            ]"
          >
            {{
              props.shortenNumber
                ? useTextFormat().shortenNumber(props.counter)
                : props.counter
            }}
          </p>
          <p v-if="props.counterSuffix" class="text-sm pb-2">
            {{ props.counterSuffix }}
          </p>
          <p
            v-if="props.showPercentage"
            class="text-sm text-gray-100"
            data-testid="progress-percentage-xl"
          >
            {{ totalText }}
            <span :class="completionColor">{{ percentageText }}</span>
          </p>
        </VRow>
        <span
          v-else
          class="text-xs"
          :class="[
            props.counterColor,
            { 'text-gray': props.counterColor === '' },
          ]"
          data-testid="progress-percentage"
        >
          <span class="font-medium mr-1">
            {{
              props.shortenNumber
                ? useTextFormat().shortenNumber(props.counter)
                : props.counter
            }}
          </span>
          <span v-if="props.counterSuffix" class="text-sm pb-2">
            {{ props.counterSuffix }}
          </span>
          <span v-if="props.showPercentage" class="text-xs text-gray-100">
            {{ totalText }}
            <span :class="completionColor">{{ percentageText }}</span>
          </span>
        </span>
      </div>
    </div>
    <div
      v-if="props.showProgress"
      class="w-full bg-gray-10 ring ring-gray-25 h-4 rounded-lg"
    >
      <div
        :style="{
          width: progressWidth,
          transitionDuration: `${props.transitionDuration}ms`,
        }"
        class="h-4 rounded-lg transition-all ease-in-out bg-gradient-to-r"
        :class="dangerOnCompletionProgressColor"
        data-testid="progress-bar"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useTextFormat } from "@/composables/text-format";

const props = defineProps({
  label: { type: String, default: "Progress" },
  counter: { type: Number, default: 0 },
  counterSuffix: { type: String, default: "" },
  counterColor: { type: String, default: "" },
  max: { type: Number, default: 100 },
  transitionDuration: { type: Number, default: 1500 },
  transitionDelay: { type: Number, default: 0 },
  showPercentage: { type: Boolean, default: true },
  showProgress: { type: Boolean, default: true },
  shortenNumber: { type: Boolean, default: true },
  dangerOnCompletion: { type: Boolean, default: false },
  successOnCompletion: { type: Boolean, default: false },
  showProgressDanger: { type: Boolean, default: false },
  tooltip: { type: String, default: null },
  tooltipPosition: {
    type: String,
    default: "right",
    validator: (value) => {
      const validVariants = ["right", "left", "top", "bottom"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid position: ${value}. Defaulting to "right".`);
        return false;
      }
      return true;
    },
  },
  xl: { type: Boolean, default: false },
});

const isTransitionDelayDone = ref(false);

const percentage = computed(() => {
  if (props.max === 0 || !isTransitionDelayDone.value) return 0;
  let percentage = Math.min(100, (props.counter / props.max) * 100).toFixed(1);
  if (isNaN(percentage)) return 0;
  if (percentage < 1) return "<1";
  return percentage;
});

const progressWidth = computed(() => percentage.value + "%");
const progressFlexClass = computed(() => {
  if (!props.xl)
    return `flex flex-row justify-between items-center ${
      props.showProgress ? "mb-2" : "mb-0.5"
    }`;
  return `flex flex-col items-start space-y-2 ${
    props.showProgress ? "mb-2" : "mb-0"
  }`;
});
const completionColor = computed(() => {
  if (props.dangerOnCompletion) {
    if (percentage.value >= 80) return "!text-google-red-600";
    else if (percentage.value >= 50) return "!text-google-yellow-600";
  } else if (percentage.value >= 100 && props.successOnCompletion)
    return "!text-google-green-600";
  return "";
});

const labelSizeClass = computed(() => (props.xl ? "text-base" : "text-sm"));

const totalText = computed(
  () =>
    `/ ${
      props.shortenNumber ? useTextFormat().shortenNumber(props.max) : props.max
    }`
);

const percentageText = computed(() => `(${percentage.value}%)`);

const dangerOnCompletionProgressColor = computed(() => {
  const defaultColor = "from-google-blue-200 to-google-blue-500";
  if (
    !props.showProgressDanger ||
    !props.dangerOnCompletion ||
    percentage.value < 50
  )
    return defaultColor;
  else if (percentage.value < 80)
    return "from-google-yellow-300 to-google-yellow-600";
  return "from-google-red-200 to-google-red-500";
});
onMounted(() => {
  setTimeout(() => {
    isTransitionDelayDone.value = true;
  }, props.transitionDelay);
});
</script>
