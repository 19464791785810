// OpenReplay implementation https://docs.openreplay.com/
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";

let tracker = null;

export function useOpenReplay() {
  const PROJECT_ID = import.meta.env.VITE_OPENREPLAY_PROJECT_ID;
  const INGESTION_POINT = import.meta.env.VITE_OPENREPLAY_INGEST_POINT;

  const openReplayConfigReady = () => {
    return PROJECT_ID && INGESTION_POINT;
  };

  const init = () => {
    if (tracker === null) {
      if (!openReplayConfigReady()) {
        return;
      }

      tracker = new Tracker({
        projectKey: PROJECT_ID,
        ingestPoint: INGESTION_POINT,
      });

      tracker.use(trackerAssist());
      tracker.start();
    }
  };

  /**
   * Function to set user in OpenReplay
   */
  const setUserInfo = async (user, appVersion, status) => {
    if (!openReplayConfigReady()) {
      return;
    }

    if (tracker === null) {
      init();
    }

    tracker.setUserID(user.email);
    tracker.setMetadata("hd", user.headDomain);
    tracker.setMetadata("appVersion", appVersion);
    tracker.setMetadata("status", status);
  };

  /**
   * Stop OpenReplay recording
   */
  const stop = () => {
    if (tracker === null) {
      return;
    }

    tracker.stop();
  };

  return {
    setUserInfo,
    init,
    stop,
  };
}
