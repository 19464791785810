<template>
  <div class="-mt-3 -ml-6 pl-2 pr-2 -mr-6 h-full overflow-x-auto relative">
    <VLoadingBlur
      v-if="props.loading"
      :loading-text="props.loadingText ? props.loadingText : undefined"
    />
    <VMultipleValueGroup
      v-model="selection"
      :reset="resetSubscribers"
      :select-all="allFilesSelected"
    >
      <table class="table-auto min-w-full relative">
        <caption class="sr-only">
          Table
        </caption>
        <thead class="bg-background border-gray-50 sticky-header relative">
          <tr>
            <th
              v-if="props.hasSelection && !props.loading && props.items.length"
              class="w-[20px]"
            >
              <VCheckbox
                select-all
                value="all"
                xs
                class="mr-[10px]"
                data-cy="vtable-selection-all-button"
              />
            </th>
            <th
              v-for="header in props.headers"
              :key="header.text"
              scope="col"
              class="select-none bg-background py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 first:pl-6"
              :class="[
                alignClass[header.align],
                header.sortable && 'cursor-pointer',
              ]"
              @click="header.sortable ? setSorting(header.value) : null"
            >
              <VRow gap="1">
                <slot :name="`header.${header.value}`" :header="header">
                  <div class="flex min-w-[150px]" data-testid="vtable-header">
                    <p data-testid="vtable-header-text">{{ header.text }}</p>
                  </div>
                </slot>
                <div
                  v-if="header.sortable"
                  class="ml-2 flex h-4 w-4 items-center justify-center"
                >
                  <VIcon
                    v-if="header.value === sortBy"
                    :name="sortDesc ? 'arrow_upward' : 'arrow_downward'"
                    class="mt-1"
                    data-testid="vtable-header-sort-arrow"
                    mini
                  />
                  <VIcon
                    v-else
                    name="sort"
                    class="mt-1 text-gray-400"
                    data-testid="vtable-header-sort"
                    mini
                  />
                </div>
              </VRow>
            </th>
            <th scope="col" class="relative w-20 py-3.5 pl-3 pr-4 sm:pr-6">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
          <tr
            v-if="selection.length"
            class="w-full h-[56px] bg-google-blue-100"
            data-cy="vtable-selection-row"
          >
            <td>
              <VIcon button @click.stop="clearSelection" name="close" small />
            </td>
            <td class="relative">
              <VRow
                class="absolute items-center top-0 left-0 h-[56px] whitespace-nowrap"
              >
                <p
                  v-if="
                    allFilesSelected ||
                    (props.totalItems && selection.length === props.totalItems)
                  "
                  class="text-sm mr-20 w-full"
                >
                  All {{ allFilesLabelCount }} files are selected
                </p>
                <VRow class="mr-20 items-center" v-else>
                  <p class="text-sm w-full">
                    {{ selection.length }} files selected
                  </p>
                  <VButton
                    v-if="props.totalItems"
                    :label="`Select all ${allFilesLabelCount} files`"
                    variant="tertiary"
                    wrap
                    bold
                    @click="() => handleSelectAll(true)"
                  />
                </VRow>
                <slot name="selection.actions"></slot>
              </VRow>
            </td>
            <td v-for="header in headers" :key="header.text"></td>
          </tr>
        </thead>
        <tbody
          class="divide-y divide-gray-25 bg-background relative"
          :class="[props.loading && 'blur']"
        >
          <template v-if="props.loading && !props.items.length">
            <tr
              v-for="n in skeletonAmount"
              :key="n"
              class="w-full h-[56px] hover:bg-google-blue-100"
            >
              <td
                v-for="header in headers"
                :key="header.text"
                class="py-1 pr-12 text-sm font-light first:pl-4 first:sm:pl-6 whitespace-nowrap"
                data-testid="vtable-loading-${header.value}"
              >
                <VSkeleton :numberOfLines="1" />
              </td>
              <td
                class="relative py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-20"
              >
                <VSkeleton :numberOfLines="1" />
              </td>
            </tr>
          </template>
          <tr v-else-if="!props.items.length && !props.loading">
            <td
              colspan="100%"
              class="py-4 text-center"
              data-testid="vtable-no-data"
            >
              No data found
            </td>
          </tr>

          <tr
            v-else
            v-for="(item, index) in props.items"
            :key="getItemKey(item, index)"
            class="w-full h-[56px] hover:bg-google-blue-100"
            :class="{ 'bg-gray-20': props.highlightRows.includes(index) }"
          >
            <td v-if="props.hasSelection">
              <VCheckbox :value="getItemKey(item, index)" xs />
            </td>
            <td
              v-for="header in headers"
              :key="header.text"
              :class="alignClass[header.align]"
              class="py-1 pr-3 text-sm font-light first:pl-4 first:sm:pl-6 whitespace-nowrap"
            >
              <slot :name="`row.${header.value}`" :item="item">
                <p data-testid="vtable-item">{{ item[header.value] }}</p>
              </slot>
            </td>
            <td
              class="relative py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-20"
              data-testid="vtable-actions"
            >
              <slot name="row.itemActions" :item="item"></slot>
            </td>
          </tr>
        </tbody>
      </table>
    </VMultipleValueGroup>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { useArrayHelper } from "@/composables/array-helper";

const emit = defineEmits([
  "update:sorting",
  "update:selection",
  "update:selectAll",
]);

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  loadingText: {
    type: String,
  },
  items: {
    type: Array,
    required: true,
  },
  totalItems: {
    type: Number,
    required: false,
  },
  itemKey: {
    type: String,
    required: false,
  },
  headers: {
    type: Array,
    required: true,
  },
  pageSize: {
    type: Number,
    default: 15,
  },
  hasSelection: {
    type: Boolean,
    default: false,
  },
  highlightRows: {
    type: Array,
    default: () => [],
  },
});

const alignClass = {
  left: "text-left",
  start: "text-left",
  end: "text-right",
  right: "text-right",
  center: "text-center",
};

// !=====================
// !======= TABLE =======
// !=====================

// !===== Sorting =====

const sortBy = ref("");
const sortDesc = ref(false);

const setSorting = (header) => {
  if (sortBy.value === header) {
    sortDesc.value = !sortDesc.value;
  } else {
    sortBy.value = header;
    sortDesc.value = false;
  }
  emit("update:sorting", { sortBy: sortBy.value, sortDesc: sortDesc.value });
};

const skeletonAmount = computed(() =>
  props.pageSize ? props.pageSize - 1 : 15
);

// !===== Selection =====

const savedPageSize = ref(props.pageSize);
const resetSubscribers = ref(false);
const selection = ref([]);
const allFilesSelected = ref(false);
const allFilesLabelCount = computed(() =>
  props.totalItems === 2000 ? "2000+" : props.totalItems
);

const getItemKey = (item, index) =>
  props.itemKey
    ? useArrayHelper().getItemProperty(item, props.itemKey)
    : item.id ?? item.name ?? `vtable-item-${index}`;

const clearSelection = () => {
  selection.value = [];
};

const handleSelectAll = (selected) => {
  if (allFilesSelected.value !== selected) {
    allFilesSelected.value = selected;
    emit("update:selectAll", selected);
  }
};

watch(
  () => selection,
  () => {
    emit("update:selection", selection.value);
  },
  { deep: true }
);

watch(
  () => selection.value,
  () => {
    handleSelectAll(false);
  },
  { deep: true }
);

watch(
  () => props.pageSize,
  () => {
    savedPageSize.value = props.pageSize;
    selection.value = [];
    resetSubscribers.value = true;

    nextTick(() => {
      resetSubscribers.value = false;
    });
  }
);
</script>

<style scoped lang="scss">
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  /* box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
}

.sticky-header::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px; /* Height of your desired border */
  @apply bg-gray-50;
  z-index: 1; /* Ensure it's above the table rows */
}
</style>
